<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
  export default {
    name: 'App',
    metaInfo: {
      meta: [
        { name: 'robots', content: process.env.NUXT_ENV_NOFOLLOW || 'noindex, nofollow' },
      ]
    },
  }
</script>
<style src="./assets/tailwind.css"></style>
<style>
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
</style>

<style>
#app {
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

</style>
